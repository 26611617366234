import React from "react";
import { Link } from "react-router-dom";

const Paginate = ({ pages, page, section }) => {
  const prevPage = page - 1;
  const nextPage = page + 1;

  const showPrevButton = page > 1;
  const showNextButton = page < pages;

  const pageNumbers = [];
  const ellipsis = <div className="border p-1 w-8 mr-2 rounded-md">...</div>;

  if (pages <= 5) {
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);

    if (page > 2) {
      pageNumbers.push(ellipsis);
    }

    if (page === pages) {
      pageNumbers.push(page - 2, page - 1);
    } else if (page === pages - 1) {
      pageNumbers.push(page - 1, page);
    } else if (page === 1) {
      pageNumbers.push(page + 1, page + 2);
    } else {
      pageNumbers.push(page - 1, page, page + 1);
    }

    if (page < pages - 1) {
      pageNumbers.push(ellipsis);
    }

    pageNumbers.push(pages);
  }

  return (
    pages > 1 && (
      <div className="flex justify-end pt-8">
        {showPrevButton && (
          <Link to={`/${section}/page/${prevPage}`}>
            <div className="border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center rounded-md">
              {"<"}
            </div>
          </Link>
        )}

        {pageNumbers.map((num, index) => {
          if (num === ellipsis) {
            return ellipsis;
          }

          return (
            <Link key={num} to={`/${section}/page/${num}`}>
              <div
                className={`border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center rounded-md ${
                  num === page ? "bg-primary text-white" : ""
                }`}
              >
                {num}
              </div>
            </Link>
          );
        })}

        {showNextButton && (
          <Link to={`/${section}/page/${nextPage}`}>
            <div className="border p-1 w-8 mr-2 hover:bg-opacity-90 flex items-center justify-center rounded-md">
              {">"}
            </div>
          </Link>
        )}
      </div>
    )
  );
};

export default Paginate;
