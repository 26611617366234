import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableCoupon, listCoupon } from "../../actions/couponActions";
import AdminLayout from "../../layout/AdminLayout";
import { useTranslation } from "react-i18next";

function DiscountsScreen() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { coupons, error, loading } = useSelector((state) => state.couponList);

  useEffect(() => {
    dispatch(listCoupon());
  }, [dispatch]);

  const showCouponAddModal = () => {
    setIsOpen(true);
  };

  const handleDisableCoupon = (id) => {
    dispatch(disableCoupon(id));

    document.location.href = "/discounts/";
  };
  const { t } = useTranslation();

  return (
    <AdminLayout>
      <div className="flex justify-end">
        <a
          href="/discounts/new"
          className="bg-primary rounded-full px-4 text-white p-2"
        >
          {t("Add Coupon Code")}
          {/* <button
          className="bg-primary rounded-full px-4 text-white p-2"
          onClick={() => alert("coming soon !")}
        >
          Add Coupon Code
        </button> */}
        </a>
      </div>
      <div className="w-full mt-4 px-4 bg-white rounded-md flex justify-between">
        <table className="table-fixed table w-full">
          <thead className="">
            <tr className="h-12 justify-start">
              <th className="text-start">{t("Offer")}</th>
              <th className="text-start">{t("Product")}</th>
              <th className="text-start">{t("Discount")}</th>
              <th className="text-start">{t("Duration")}</th>
              <th className="text-start">{t("Used")}</th>
              <th className="text-start">{t("Is valid")}</th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? "Loading..."
              : error
              ? error
              : coupons.map((coup) => (
                  <tr className="border-b h-12" key={coup.id}>
                    <td>{coup.code}</td>
                    <td>
                      {coup.product?.length > 20
                        ? coup.product?.substring(0, 20) + "..."
                        : coup.product}
                    </td>

                    <td>{coup.discount}%</td>
                    <td>
                      {coup.duration < 0
                        ? t("Expired")
                        : coup.duration + " days"}
                    </td>
                    <td>
                      {coup.has_max
                        ? `${coup.used}/${coup.max_count}`
                        : coup.used}
                    </td>
                    <td>{coup.is_valid ? "Valid" : "Not Valid"}</td>

                    <td>
                      {coup.is_valid && (
                        <button
                          className="border rounded-full bg-gray-100 px-2 opacity-70 hover:opacity-100"
                          onClick={() => handleDisableCoupon(coup.id)}
                        >
                          Désactiver
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      {/* <AddCouponDiscount isOpen={isOpen} setIsOpen={setIsOpen} /> */}
    </AdminLayout>
  );
}

export default DiscountsScreen;
