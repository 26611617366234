import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../actions/userActions";
import { useTranslation } from "react-i18next";

function SideBar() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const handleLogout = () => {
    dispatch(logout());
  };

  const { t, i18n } = useTranslation();

  return (
    <div className=" ">
      <ul className=" ">
        <div className="flex min-h-screen    flex-col justify-between">
          <div>
            <div className="mx-auto pb-3">
              <a href="/" className="px-3 relative pr-3">
                <img className="w-40" src="/logo_white.png" alt="" />
              </a>
            </div>
            <div className="pb-4">
              <select
                className="outline-none bg-transparent"
                value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                name=""
                id=""
              >
                <option selected="selected" hidden>
                  {i18n.language == "fr" ? "French" : "English"}
                </option>
                <option value="fr">French</option>
                <option value="en">English</option>
              </select>
            </div>

            <Link to="/">
              <li
                className={`cursor-pointer ${
                  pathname === "/" &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </span>
                <span className="pl-3 hidden md:block">{t("Dashboard")}</span>
              </li>
            </Link>

            <Link to="/productlist">
              <li
                className={`cursor-pointer ${
                  pathname.includes("productlist") &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                </span>
                <span className="pl-3 hidden md:block">
                  {t("Sections & Products")}
                </span>
              </li>
            </Link>

            <Link to="/orderlist">
              <li
                className={`cursor-pointer ${
                  pathname.includes("orderlist") &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span className="px-3 hidden md:block ">{t("Orders")}</span>
              </li>
            </Link>
            <Link to="/add-offline-order">
              <li
                className={`cursor-pointer ${
                  pathname.includes("add-offline-order") &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span className="pl-3 hidden md:block">
                  {t("Offline orders")}
                </span>
              </li>
            </Link>

            <Link to="/discounts">
              <li
                className={`cursor-pointer ${
                  pathname.includes("discounts") &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </span>

                <span className="pl-3 hidden md:block">
                  {/* {t("Coupons & discounts")} */}
                  {t("Coupons")}
                </span>
              </li>
            </Link>
            <Link to="/tickets">
              <li
                className={`cursor-pointer ${
                  pathname.includes("tickets") &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </span>

                <span className="pl-3 hidden md:block">{t("Tickets")}</span>
              </li>
            </Link>
            {/* <Link to="/donations">
              <li
                className={`cursor-pointer ${
                  pathname.includes("tickets") &&
                  "rounded-l-full bg-[#F5F5F5] text-primary "
                } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </span>

                <span className="pl-3 hidden md:block">Donations</span>
              </li>
            </Link> */}
          </div>
          <div className="pb-6  ">
            <button onClick={handleLogout} className="w-full rounded-md p-2 ">
              <div className="flex items-center">
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                    />
                  </svg>
                </span>
                <div className="pl-3 hidden md:block">{t("Logout")}</div>
              </div>
            </button>
          </div>
        </div>
      </ul>
    </div>
  );
}

export default SideBar;
