import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCoupon } from "../../actions/couponActions";
import { listAdminProducts } from "../../actions/productActions";
import AdminLayout from "../../layout/AdminLayout";
import { useTranslation } from "react-i18next";

const discounts = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100,
];

const NewDiscountScreen = () => {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [product, setProduct] = useState();
  const [discount, setDiscount] = useState(5);
  const [validFrom, setValidFrom] = useState();
  const [validTo, setValidTo] = useState();
  const [hasMax, setHasMax] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [maxCountError, setMaxCountError] = useState("");

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const handleAddCoupon = (e) => {
    e.preventDefault();
    if (hasMax == true) {
      if (maxCount === "" || maxCount === 0) {
        setMaxCountError("This value must be required and not equal a 0");
      } else {
        const isNotInt = !Number.isInteger(parseFloat(maxCount));
        const hasE = maxCount.toLowerCase().includes("e");

        if ((isNotInt && maxCount !== "") || hasE) {
          setMaxCountError("This value must be an integer ");
        } else {
          const coupon = {
            product,
            code,
            discount,
            valid_from: validFrom,
            valid_to: validTo,
            has_max: hasMax,
            max_count: maxCount,
          };
          // console.log("send");
          dispatch(createCoupon(coupon));
        }
      }
    } else {
      const coupon = {
        product,
        code,
        discount,
        valid_from: validFrom,
        valid_to: validTo,
        has_max: false,
        max_count: 0,
      };
      // console.log("send");
      dispatch(createCoupon(coupon));
    }
  };

  useEffect(() => {
    dispatch(listAdminProducts("", "", 1));
  }, []);

  const { t } = useTranslation();

  return (
    <AdminLayout>
      <h1 className="font-semibold text-xl">{t("Add coupon code ")}</h1>
      {error && error}
      <form onSubmit={handleAddCoupon} className="pt-4 ">
        <div className="flex items-center pt-8">
          <div className="w-60">
            <span className="font-semibold whitespace-nowrap uppercase">
              {" "}
              {t("Ticket")}{" "}
            </span>
          </div>
          <div className="border w-full rounded-md">
            <select
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              className="w-full bg-transparent p-2"
              // required
            >
              <option> {t("Select ticket")} </option>
              {products.map((p) => (
                <option key={p.id} value={p._id}>
                  {p.name}
                </option>
              ))}
            </select>
            {/* <input type="text" placeholder='Ticket' className='p-2 bg-transparent outline-none w-full  ' /> */}
          </div>
        </div>

        <div className="flex items-center pt-8">
          <div className="w-60">
            <span className="font-semibold uppercase whitespace-nowrap">
              Discount Code{" "}
            </span>
          </div>
          <div className="border w-full rounded-md">
            <input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              type="text"
              placeholder="CODE"
              className="p-2  bg-transparent outline-none w-full  "
              required
            />
          </div>
        </div>

        <div className="flex items-center pt-8">
          <div className="w-60">
            <span className="font-semibold whitespace-nowrap uppercase">
              {t("Discount percentage")}{" "}
            </span>
          </div>
          <div className="border w-full rounded-md">
            <select
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              name="dis"
              className="w-full p-2 outline-none bg-transparent"
              id=""
              required
            >
              {discounts.map((dis) => (
                <option value={dis} key={dis}>
                  {dis}%
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex items-center pt-8">
          <div className="w-60">
            <span className="font-semibold whitespace-nowrap uppercase">
              {t("Duration")}{" "}
            </span>
          </div>
          <div className="grid w-full grid-cols-2 gap-3">
            <div>
              {/* <label htmlFor="">VALID FROM :</label> */}
              <div className="border my-2 rounded-md">
                <input
                  type="date"
                  value={validFrom}
                  onChange={(e) => setValidFrom(e.target.value)}
                  className="p-2 bg-transparent outline-none w-full  "
                  required
                />
              </div>
            </div>
            <div>
              {/* <label htmlFor="">VALID TO :</label> */}
              <div className="border my-2 rounded-md">
                <input
                  type="date"
                  value={validTo}
                  onChange={(e) => setValidTo(e.target.value)}
                  className="p-2 bg-transparent outline-none w-full  "
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center pt-8">
          <div className="w-60 flex font-bold items-center">
            <span className="font-semibold whitespace-nowrap uppercase">
              {t("Has a max")}{" "}
            </span>
          </div>
          <div className="w-full">
            <div class="w-full flex justify-start ">
              <label
                // onClick={handleDisableProduct(!product?.isActive)}
                for="toggleB"
                class="flex items-center cursor-pointer mx-2"
              >
                <div class="relative">
                  <input
                    type="checkbox"
                    id="toggleB"
                    class="sr-only"
                    checked={hasMax}
                    value={hasMax}
                    onChange={(v) => {
                      setMaxCountError("");
                      setHasMax(!hasMax);
                      setMaxCount(0);
                    }}
                  />
                  <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                  <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                </div>
              </label>
              <div className="border w-full rounded-md">
                <input
                  disabled={!hasMax}
                  value={maxCount}
                  onChange={(e) => {
                    setMaxCountError("");
                    setMaxCount(e.target.value);
                    const isNotInt = !Number.isInteger(
                      parseFloat(e.target.value)
                    );
                    const hasE = e.target.value.toLowerCase().includes("e");

                    if ((isNotInt && e.target.value !== "") || hasE) {
                      setMaxCountError(t("This value must be an integer."));
                    }
                  }}
                  type="number"
                  step={1}
                  placeholder="Count"
                  required={hasMax}
                  className="p-2  bg-transparent outline-none w-full  "
                />
              </div>
            </div>
            {maxCountError ? (
              <p className="text-xs text-red-500">{maxCountError}</p>
            ) : null}
          </div>
        </div>

        <div className="py-8 flex justify-end gap-2">
          <a
            href="/discounts"
            className="p-2 text-center w-40 rounded-md border border-primary "
          >
            {t("Cancel")}
          </a>
          <button
            type="submit"
            className="p-2 w-40 rounded-md bg-primary text-white "
          >
            {t("Save")}
          </button>
        </div>
      </form>
    </AdminLayout>
  );
};

export default NewDiscountScreen;
