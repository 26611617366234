import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrganizerOrderDetails } from "../../actions/orderActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import AdminLayout from "../../layout/AdminLayout";
import { useTranslation } from "react-i18next";

function OrderDetailAdminScreen() {
  const { id } = useParams();
  const { t } = useTranslation();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizerOrderDetails(id));
  }, [id, dispatch]);

  return (
    <AdminLayout>
      <div className="">
        <div className=" flex items-center justify-between  ">
          <div className="flex items-center gap-1">
            <a className="opacity-70 flex items-center" href="/orderlist">
              {t("Orders")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </a>
            <span>{t("Detail")}</span>
          </div>

          {/* <a href={`https://aylink.ma/api/orders/${order?._id}/export-ticket/`}>
            <div className="flex items-center bg-secondary gap-x-2  rounded-full py-2 px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                />
              </svg>
              Ticket
            </div>
          </a> */}
          {/* <button className='p-2 bg-secondary rounded-full px-4' >Download PDF </button> */}
        </div>

        {loading ? (
          <Loader />
        ) : error ? (
          <Message />
        ) : (
          <>
            <div className="pt-4">
              {order.orderItems.length === 0 ? (
                <Message>{t("Order is empty")}</Message>
              ) : (
                <div className="">
                  {/* info perso */}
                  <div className="border bg-white rounded-xl p-3">
                    <div>
                      {order.isOffline && (
                        <div className="text-red-600 pb-3">
                          {t("Offline Order")}
                        </div>
                      )}
                    </div>
                    <h1>
                      <span className="border-b">{t("Customer info")}</span>
                    </h1>
                    <div className="py-3">
                      {!order.isOffline && (
                        <div>
                          <div>
                            {t("First Name")} : {order.user.first_name}
                          </div>
                          <div>
                            {t("Last Name")} : {order.user.last_name}
                          </div>
                          <div>
                            {t("Email")} : {order.user.email}
                          </div>
                          <div>
                            {t("Phone Number")} : {order.user.phone}
                          </div>
                        </div>
                      )}
                      {order.isOffline && (
                        <div>
                          <div>
                            {t("First Name")} : {order.infoClient.firstName}
                          </div>
                          <div>
                            {t("Last Name")} : {order.infoClient.lastName}
                          </div>

                          <div>
                            {t("Email")} : {order.infoClient.email}
                          </div>
                          <div>
                            {t("Phone Number")} : {order.infoClient.phone}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="border mt-3 bg-white rounded-xl p-3">
                    <h1>
                      <span className="border-b">{t("Items")}</span>
                    </h1>
                    {order.orderItems.map((item, index) => (
                      <div className="pt-3" key={index}>
                        <div className="flex py-2 items-center border-b justify-between">
                          <div className="flex ">
                            <img
                              src={item.image}
                              alt={item.name}
                              className="w-24 h-24 rounded-xl object-cover"
                            />
                            <div className="pl-4">
                              <a
                                className="text-md "
                                target={"_blank"}
                                href={`https://aylink.ma/p/${item.organizer.slug}/${item.product.slug}`}
                              >
                                {item.name}
                              </a>
                              {item.isDaily && !item.hasDate ? (
                                <div className=" opacity-90 ">
                                  Date : {item.event_date}
                                </div>
                              ) : null}
                              {item.isDaily &&
                              item.hasDate &&
                              item.product?.hasDate ? (
                                <div className=" opacity-90 ">
                                  Date : {item.product?.event_date}
                                </div>
                              ) : null}
                              <div className="text- opacity-90 ">
                                <p>
                                  {item.variationName} {item.variationValue}
                                </p>
                                <p>
                                  {item.customization &&
                                    `Customization : ${item.customization}`}
                                </p>
                              </div>
                              <div className="text- opacity-90 ">
                                {t("Price")} : {item.price} MAD
                              </div>
                              {/* <div className="text- opacity-90 " >Organizer : {item.organizer} </div> */}
                            </div>
                          </div>
                          <div>
                            {item.qty} x {item.price} MAD ={" "}
                            {item.qty * parseFloat(item.price)} MAD
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="py-3 float-right border bg-white  rounded-md p-3 mt-3 ">
                    <h1 className="">CMI</h1>
                    <div className="text-sm opacity-75">
                      Transaction ID : {order.cmiTransId} <br />
                      Updated time : {order.cmiUpdatedTime} <br />
                      Email : {order.cmiEmail} <br />
                    </div>
                    <div className="pt-3 text-sm">
                      Paid At : {order.paidAt} <br />
                    </div>
                    <div className="py-2 font-semibold">
                      Total :
                      {order.orderItems.reduce(function (a, b) {
                        return a + parseFloat(b.price) * b.qty;
                      }, 0)}{" "}
                      MAD
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </AdminLayout>
  );
}

export default OrderDetailAdminScreen;
