import axios from "../axios";
import {
  COUPON_CREATE_FAIL,
  COUPON_CREATE_REQUEST,
  COUPON_CREATE_SUCCESS,
  COUPON_DELETE_FAIL,
  COUPON_DELETE_REQUEST,
  COUPON_DELETE_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
} from "../constants/couponConstants";
import { logout } from "./userActions";

export const createCoupon = (coupon) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COUPON_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
      couponList: { coupons },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/coupons/add-coupon/`, coupon, config);
    document.location.href = "/discounts/";
    dispatch({
      type: COUPON_CREATE_SUCCESS,
      payload: data,
    });

    // dispatch({
    //   type: COUPON_LIST_SUCCESS,
    //   payload:{coupons:[...coupons,data.coupon]}
    // })
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: COUPON_CREATE_FAIL,
      payload: message,
    });
  }
};

export const disableCoupon = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "COUPON_DISABLE_REQUEST",
    });

    const {
      userLogin: { userInfo },
      couponList: { coupons },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`/coupons/${id}/disable-coupon/`, config);

    dispatch({
      type: "COUPON_DISABLE_SUCCESS",
      payload: data,
    });

    // dispatch({
    //   type: COUPON_LIST_SUCCESS,
    //   payload: { coupons: coupons.filter((el) => el._id !== id) },
    // });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: "COUPON_DISABLE_FAIL",
      payload: message,
    });
  }
};

export const deleteCoupon = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COUPON_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
      couponList: { coupons },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`/coupons/${id}`, config);

    dispatch({
      type: COUPON_DELETE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: COUPON_LIST_SUCCESS,
      payload: { coupons: coupons.filter((el) => el._id !== id) },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: COUPON_DELETE_FAIL,
      payload: message,
    });
  }
};

export const listCoupon = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COUPON_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.get(`/coupons/`, config);

    dispatch({
      type: COUPON_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    console.log(message);

    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }

    dispatch({
      type: COUPON_LIST_FAIL,
      payload: message,
    });
  }
};
