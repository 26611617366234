import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { listOrganizers } from '../../actions/organizerActions'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import AdminLayout from '../../layout/AdminLayout'

const OrganizerItem = ({organizer}) => {
    const ref = useRef(null)

    const history = useHistory()

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)

    useOnClickOutside(ref,()=> setIsOpen(false))

    const deleteHandler = (id) => (ev) => {
        if (window.confirm('Are you sure')) {
        
        }
    }

    const handleDisableProduct = (id,isActive) => (ev)  => {
        

    }


    return (
        <div  className='flex justify-between mb-2 w-full py-2 px-4 border rounded-sm'>
            <div className='flex'>
                <div className='w-32 float-left   '>
                    <img src={organizer.cover} alt="" />
                </div>
                <img className='w-8 h-8' src={organizer.avatar} alt="" />

                
                <div className='pl-2 float-right'>
                <div>
                    {organizer.name}
                </div>
             
                <div className='text-sm opacity-70'>
                    {organizer.is_active ? "Active":"Not Active"}
                </div>
                
                </div>
            </div>

            <div className='cursor-pointer relative'>
                <svg xmlns="http://www.w3.org/2000/svg"  onClick={()=> setIsOpen(!isOpen)  } className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>       

                <div ref={ref} className={`p-2 ${isOpen ? 'block':'hidden'} absolute bg-white shadow-md border z-50 right-0`}>
        
                <button onClick={()=> history.push(`/admin/organizer/${organizer._id}/edit/`)} className='whitespace-nowrap hover:bg-gray-50 py-1 px-4'>
                    Edit Organizer
                </button>
                <button onClick={handleDisableProduct(organizer._id,!organizer.is_active)} className='whitespace-nowrap disabled:bg-gray-100 mb-2 hover:bg-gray-50 py-1 px-4'>
                    {organizer.is_active ? 'Disable':'Activate'} Organizer
                </button>

                <hr />

                <button onClick={deleteHandler(organizer._id)} className='whitespace-nowrap text-red-600 hover:bg-gray-50 py-1 px-4'>
                    Delete Organizer
                </button>
                </div>
            </div>
            
            
        </div>
    )
}
   


function OrganizerListAdmin() {

    const dispatch = useDispatch()

    const { loading, error, organizers, page, pages } = useSelector((state) => state.organizerList)
  
    useEffect(() => {
      dispatch(listOrganizers())
    }, [])
    


    return (
        <AdminLayout>

            <div className='flex gap-3'>

                <div className='w-3/4'>
                    <div>Organizer List </div>
                    <div className='pt-4'>
                        {organizers.map(org => <OrganizerItem  organizer={org}/>)}
                    </div>
                </div>
                <div className='w-1/4'>
                    <button className='p-3 bg-black text-white rounded-full'>Add New Organizer</button>
                </div>
               

            </div>

        </AdminLayout>
    
    )
}

export default OrganizerListAdmin