import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { login } from "../actions/userActions";

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const loginWithGoogle = (e) => {
    e.preventDefault();
    alert("login with google");
  };

  return (
    <div
      className="min-h-screen bg-no-repeat bg-gray-400 "
      style={{ backgroundImage: "url(./cover_login.png)" }}
    >
      <div className="max-w-lg text-white mx-auto p-3 pt-32">
        <div className="w-60 pb-4 mx-auto">
          <img src="/logo_white.png" alt="" />
        </div>
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <form className="w-full " onSubmit={submitHandler}>
          <div controlId="email">
            <div className="opacity-80">Email Address</div>
            <div className="border border-white rounded-full my-3">
              <input
                className="outline-none w-full p-3 bg-transparent  rounded-full "
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
          </div>

          <div controlId="password">
            <div className="opacity-80">Password</div>
            <div className="border border-white rounded-full my-3">
              <input
                type="password"
                className="outline-none w-full p-3 bg-transparent  rounded-full"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="pt-4 flex items-center justify-center">
            <button
              className="p-3  px-10 rounded-full text-center text-white bg-secondary"
              type="submit"
            >
              Connexion
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
