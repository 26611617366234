import React, { useEffect, useState } from "react";
import axiosInstance from "../axios";
import { useTranslation } from "react-i18next";
// import ProgressBar from "./ProgressBar";

function DaysLeft() {
  const [days, setDays] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    axiosInstance.get("/stats/days-left/").then((res) => {
      setDays(res.data.days_left);
    });
  }, []);
  return (
    <div className="p-2 mt-2 w-full bg-white border rounded-md">
      <h1 className="">{t("Days left")}</h1>
      <div className="py-1">
        <span className="text-2xl">
          {days < 0 ? (
            <span className="text-red-600">{t("Epired")}</span>
          ) : (
            days + " " + t("days")
          )}
        </span>
      </div>
      {/* <ProgressBar number={days / 300} /> */}
    </div>
  );
}

export default DaysLeft;
