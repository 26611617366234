import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductOverview from "../../components/ProductOverview";
import SalesAnalytics from "../../components/SalesAnalytics";
import Stats from "../../components/Stats";
import AdminLayout from "../../layout/AdminLayout";
import Inventory from "../../components/Inventory";
import DaysLeft from "../../components/DaysLeft";

import Calendar from "react-calendar";
import "../../style/Calendar.css";
import { useTranslation } from "react-i18next";

function Dashboard({ history }) {
  const { t, i18n } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [userInfo, history]);

  return (
    <AdminLayout>
      <div className="pb-2">
        <h1 className="tetx-xl ">{t("Dashboard")}</h1>
      </div>
      <Stats />

      <div className="py-4">
        <h1>{t("Stats")}</h1>
        <div className="pt-3 grid md:grid-cols-5 gap-3">
          <div className="md:col-span-3 bg-white rounded-md">
            <SalesAnalytics />
          </div>
          <div className=" md:col-span-2">
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3 ">
              <div className="">
                <Calendar
                  locale={i18n.language}
                  showNavigation={false}
                  view="month"
                  // tileClassName={colorRange}
                  className={"p-2 w-full border-none rounded-md "}
                />
              </div>
              <div className="pt-2  ">
                <Inventory number={10} />
                <DaysLeft />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductOverview />

      {/* <Confirm onConfirm={()=>alert("ok")} open={true}  >
                Vous êtes sûr de supprimer ce Control ! 
            </Confirm> */}
    </AdminLayout>
  );
}

export default Dashboard;
