import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/AdminLayout";
import { useRef } from "react";
import { listSection } from "../../actions/sectionActions";
import { useDispatch, useSelector } from "react-redux";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  updateProduct,
  listProductDetails,
  deleteImage,
} from "../../actions/productActions";
import { Prompt, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Variation = ({
  product,
  variation,
  isVariationOpen,
  setVariation,
  setHasVariation,
  setIsVariationOpen,
}) => {
  const [name, setName] = useState("");

  const [optionValue, setOptionValue] = useState("");
  const [priceValue, setPriceValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [attributes, setAttributes] = useState([]);

  const [variationName, setVariationName] = useState("");
  const [variationAttr, setVariationAttr] = useState([]);

  const addAttribute = (e) => {
    setVariationAttr([
      ...attributes,
      { name: optionValue, price: parseFloat(priceValue) },
    ]);
    setAttributes([
      ...attributes,
      { name: optionValue, price: parseFloat(priceValue) },
    ]);
    setPriceValue(0);
    setOptionValue("");
  };
  const { t } = useTranslation();

  const handleAddVariation = async () => {
    // const data = await axios.post(
    //   `/api/products/${product._id}/variations/add/`,
    //   { name: name, attributes }
    // );

    if (name !== "" && attributes.length > 0) {
      setVariation({ name: name, attributes });
      setHasVariation(true);
    } else {
      alert("Please complte info");
    }

    setIsOpen(false);
  };

  const handleDeleteVariation = () => async (e) => {
    const isOk = window.confirm("Are you sure you want to delete variation ! ");

    if (isOk) {
      setHasVariation(false);
      setIsVariationOpen(false);

      setVariation({});
      setVariationAttr([]);
      setVariationName({});
      setAttributes([]);
      setName("");
    }
  };
  useEffect(() => {
    setVariation(product.variation);
    setVariationName(product.variation?.name);
    setVariationAttr(product.variation?.attributes);

    // setVariation({ name: "test", attributes: [{ name: "ee", price: 23 }] });
  }, [product]);

  const handleEditVariation = (id) => (event) => {
    // alert(id);
    setOpenEdit(true);
    // updateVariationAttribute;
  };

  return (
    <div>
      {variation && variation.name ? (
        <div>
          <label>{variation && variation.name}</label>{" "}
          <button
            onClick={handleDeleteVariation()}
            className="border-b opacity-70 ml-6 hover:opacity-100"
          >
            Delete
          </button>
          <span
            onClick={handleEditVariation(variation._id)}
            className="border-b cursor-pointer text-center ml-6 opacity-70 hover:opacity-100"
          >
            Edit
          </span>
          <div className="pt-4">
            {variation?.attributes?.map((item) => (
              <li className="pt-1">
                {item.name} - {item.price}
                {/* <span onClick={handleEditAttribute(item.id)}>Edit</span> */}
              </li>
            ))}
          </div>
        </div>
      ) : (
        isVariationOpen && (
          <div className="fixed top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 left-1/2 border shadow-sm bg-white p-3 rounded-md  ">
            <div>
              <div className="w-1/2">
                <div>{t("Variation Name")}</div>
                <div className="border rounded-md mt-3">
                  <input
                    type="text"
                    className="p-2 w-full outline-none bg-transparent"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setVariationName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="pt-4">{t("Variation attributes")}</div>

              <div className="pt-2">
                <div className="flex gap-3">
                  <div className="border w-5/12 rounded-md">
                    <input
                      value={optionValue}
                      onChange={(e) => {
                        setOptionValue(e.target.value);
                      }}
                      type="text"
                      className="p-2  outline-none bg-transparent w-full"
                      placeholder={t("Name")}
                    />
                  </div>

                  <div className="border w-5/12 rounded-md">
                    <input
                      value={priceValue}
                      onChange={(e) => setPriceValue(e.target.value)}
                      type="number"
                      className="p-2  outline-none bg-transparent w-full"
                      placeholder={t("Price")}
                    />
                  </div>
                  <div className="p-2 w-2/12" onClick={addAttribute}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>

                <div className="pt-3">
                  {variationAttr?.map((variat, index) => (
                    <div key={index} className="p-2 mt-1  w-full">
                      {/* {item.price} */}

                      <div className="flex gap-3">
                        <div className="border w-5/12 rounded-md">
                          <input
                            value={variat.name}
                            disabled
                            type="text"
                            className="p-2  outline-none bg- w-full"
                            placeholder={t("Name")}
                          />
                        </div>

                        <div className="border w-5/12 rounded-md">
                          <input
                            value={variat.price}
                            disabled
                            // onChange={(e) => setPriceValue(e.target.value)}
                            type="number"
                            className="p-2  outline-none bg-transparent w-full"
                            placeholder={t("Price")}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* {attributes?.map((item) => ( 
                     <div key={item.value} className="p-2 mt-1  w-full">
                      

                      <div className="flex gap-3">
                        <div className="border w-5/12 rounded-md">
                          <input
                            value={item.name}
                            disabled
                            type="text"
                            className="p-2  outline-none bg- w-full"
                            placeholder={t("Name")}
                          />
                        </div>

                        <div className="border w-5/12 rounded-md">
                          <input
                            value={item.price}
                            disabled
                            onChange={(e) => setPriceValue(e.target.value)}
                            type="number"
                            className="p-2  outline-none bg-transparent w-full"
                            placeholder={t("Price")}
                          />
                        </div>
                        
                      </div>
                    </div> 
                  ))} */}
                </div>
              </div>
              <div className="pt-2 flex justify-end gap-4 pr-32">
                <div
                  className="border border-primary px-4 cursor-pointer text-primary p-2 rounded-md"
                  onClick={() => setIsVariationOpen(false)}
                >
                  {t("Cancel")}
                </div>
                <div
                  className="bg-primary px-4 cursor-pointer text-white p-2 rounded-md"
                  onClick={handleAddVariation}
                >
                  {t("Save")}
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div>
        {openEdit && (
          <>
            <div className="fixed top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 left-1/2 border shadow-sm bg-white p-3 rounded-md  ">
              <div className="w-[400px]">
                <h1 className="text-[17px] py-2">
                  Modifier Variation <b>{variation.name}</b>
                </h1>

                <div className="pt-2">
                  {variationAttr?.map((variat, index) => (
                    <div key={index} className="p-2 mt-1  w-full">
                      <div className="flex gap-3">
                        <div className="border w-5/12 rounded-md">
                          <input
                            value={variationAttr[index]?.name || variat.name}
                            onChange={(value) => {
                              const newVariations = [...variationAttr];
                              newVariations[index].name = value.target.value;
                              setVariationAttr(newVariations);
                            }}
                            type="text"
                            className="p-2  outline-none bg- w-full"
                            placeholder={t("Name")}
                          />
                        </div>

                        <div className="border w-5/12 rounded-md">
                          <input
                            value={variationAttr[index]?.price || variat.price}
                            onChange={(value) => {
                              const newVariations = [...variationAttr];
                              newVariations[index].price = value.target.value;
                              setVariationAttr(newVariations);
                            }}
                            type="number"
                            className="p-2  outline-none bg-transparent w-full"
                            placeholder={t("Price")}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pt-2 flex w-5/12  gap-3">
                  <div
                    onClick={() => setOpenEdit(false)}
                    className="border cursor-pointer border-primary  text-primary py-2  px-4 rounded-md "
                  >
                    {t("Cancel")}
                  </div>
                  <div
                    onClick={() => {
                      setVariation({
                        name: variationName,
                        attributes: variationAttr,
                      });
                      setOpenEdit(false);
                    }}
                    className="bg-primary cursor-pointer  text-white py-2  px-4 rounded-md "
                  >
                    {t("Edit")}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const LayoutProductSection = (props) => {
  return (
    <div className=" border rounded-md my-2">
      <div className="p-2 rounded-t-xl bg-gray-100 ">{props.title}</div>
      <div className="p-4 bg-white rounded-b-xl">{props.children}</div>
    </div>
  );
};

const imageMimeType = /image\/(png|jpg|jpeg)/i;

const ProductEditScreen2 = ({ match }) => {
  const productId = match.params.id;
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const [hasVariation, setHasVariation] = useState(false);

  const imageRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [isVariationOpen, setIsVariationOpen] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [variation, setVariation] = useState({});

  const handleProductFiles = (event) => {
    const data = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (!event.target.files[i].type.match(imageMimeType)) {
        alert("image type not matching ");
        return;
      }
      data.push(event.target.files[i]);
    }
    setProductImages((old) => [...old, ...data]);
  };

  const handleDeleteImage = (idx) => {
    const temp = [...productImages];
    temp.splice(idx, 1);
    setProductImages(temp);
  };

  const handleDeleteExistImage = (id) => {
    const isOk = window.confirm(
      "Are you sure you want to delete definitly this image ! "
    );
    if (isOk) {
      dispatch(deleteImage(productId, id));
    }
  };

  const sectionList = useSelector((state) => state.sectionList);
  const {
    loadin: sectionsLoading,
    error: sectionsError,
    sections,
  } = sectionList;

  useEffect(() => {
    dispatch(listSection());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname.includes("product/new") && shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, []);

  const [imagesError, setImagesError] = useState();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [eventDate, setEventDate] = useState("");
  const [eventDateError, setEventDateError] = useState("");
  const [chooseDate, setChooseDate] = useState(false);

  const isImmediately = watch("isImmediately");
  const isCustomized = watch("isCustomized");
  const hasNoEndDate = watch("hasNoEndDate");

  const onSubmit = (data) => {
    data.variation = variation;
    data.hasVariation = hasVariation;

    setImagesError("");
    if (!isImmediately) {
      data.eventDate = moment(fromDate).format();
      if (!hasNoEndDate) {
        data.endDate = moment(toDate).format();
      } else {
        data.endDate = null;
      }
    } else {
      data.eventDate = null;
      data.endDate = null;
    }
    data.hasDate = chooseDate;
    var check = true;
    if (chooseDate && eventDate !== "") {
      data.event_date = eventDate;
      check = true;
    } else {
      setEventDateError(t("Product date required"));
      check = false;
    }

    if (productImages.length + product?.images?.length > 0) {
      check = true;
    } else {
      setImagesError(t("At least one image is required"));
      check = false;
    }
    // console.log(productImages.length + product?.images?.length);

    if (check === true) {
      dispatch(updateProduct(data, productImages, variation));
    } else {
      alert(t("Please confirm which fields are required"));
    }
  };

  useEffect(() => {
    if (!product.name || product._id !== Number(productId)) {
      dispatch(listProductDetails(productId));
      dispatch(listSection());
    } else {
      // update data
      setValue("name", product.name);
      setValue("_id", product._id);
      setValue("price", product.price);
      setValue("section", product?.section?._id);
      setValue("countInStock", product.countInStock);
      setValue("description", product.description);
      setValue("isCustomized", product.isCustomized);
      setValue("isImmediately", product.isImmediately);
      setValue("customizationTitle", product.customizationTitle);
      setValue("variation", product.variation);
      setHasVariation(product.hasVariation);
      setValue("hasNoEndDate", product.hasNoEndDate);
      product.eventDate && setFromDate(new Date(product.eventDate));
      !product.hasNoEndDate && setToDate(new Date(product.endDate));
      if (product.hasDate) {
        setChooseDate(true);
      } else {
        setChooseDate(false);
      }
      setEventDate(product.event_date);
      // setFromDate(
      //   product.eventDate ? moment(product.eventDate).format() : new Date()
      // );
      // setToDate(product.toDate ? moment(product.toDate).format() : new Date());
      // setFromDate(product.eventDate);
      // setToDate(product.endDate);
    }
  }, [product, productId]);

  useEffect(() => {
    if (successUpdate) {
      document.location.href = "/productlist";
    }
  }, [successUpdate]);

  const { t } = useTranslation();

  return (
    <>
      <AdminLayout>
        <Prompt
          when={shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div id="main" className="flex  pb-6 justify-between">
          <h1 className="text-xl font-600 ">{t("Edit Product")}</h1>
        </div>

        {/* {loadingCreate && <Loader />}
        {errorCreate && <Message variant="danger">{errorCreate}</Message>} */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <LayoutProductSection title={t("Media")}>
            <div className="pb-2 text-sm text-gray-600">
              <p>
                {t("* Add up to 3 photos so buyers can see all the details")}
              </p>
              <p>{t("* 1x1 ratio")}</p>
            </div>
            <div className="flex flex-wrap  gap-3 ">
              <div>
                <input
                  type="file"
                  ref={imageRef}
                  className="hidden"
                  onChange={handleProductFiles}
                  accept="image/*"
                  multiple
                />
              </div>
              {product?.images?.map((item) => (
                <div key={item.id} className="relative">
                  <div
                    onClick={() => handleDeleteExistImage(item._id)}
                    className="absolute cursor-pointer top-2 right-2 text-white hover:text-black"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <img
                    className="w-32 h-32 object-cover rounded-md"
                    src={item?.image}
                  />
                </div>
              ))}
              {productImages.map((file, id) => (
                // <Photo file={item} key={id} />
                <div className="relative" key={id}>
                  <div
                    onClick={() => handleDeleteImage(id)}
                    className="absolute cursor-pointer top-2 right-2 text-white hover:text-black"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <img
                    src={URL.createObjectURL(file)}
                    className="w-32 h-32 object-cover rounded-md"
                    alt=""
                  />
                </div>
              ))}

              <div
                onClick={() => imageRef.current.click()}
                className="w-32 h-32 flex border-dotted border-2  rounded-md items-center justify-center cursor-pointer bg-gray-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
              </div>
            </div>

            {imagesError && (
              <p className="text-xs italic text-red-500"> {imagesError} </p>
            )}
          </LayoutProductSection>

          <LayoutProductSection title={t("Product Information")}>
            <div className="pb-2 text-sm text-gray-600">
              <p>
                {t("* choose a clear, short title to describe your product")}
              </p>
              <p>
                {t("* You can add sections from the (Products & Sections)")}
              </p>
            </div>
            <div className="pt-2">
              <div className="py-2">
                <label className="">
                  {t("Title")} <span className="required"></span>
                </label>
                <div
                  className={` border rounded-md mt-1 ${
                    errors.name && "border-red-600"
                  }`}
                >
                  <input
                    type="text"
                    placeholder={t("Title")}
                    className="p-2 w-full outline-none focus:border-blue-300 bg-transparent"
                    {...register("name", {
                      required: t("Title is required ! "),
                      minLength: {
                        value: 5,
                        message: t(
                          "The title must be at least 5 characters long"
                        ),
                      },
                    })}
                  />
                </div>
                {errors.name && (
                  <p className="text-xs italic text-red-500">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <div className=" my-2 ">
                <label>{t("Section")}</label>
                {sectionsLoading ? (
                  "loading .."
                ) : sectionsError ? (
                  sectionsError
                ) : (
                  <div className="border rounded-md">
                    <select
                      className="p-2 outline-none w-full bg-transparent"
                      {...register("section")}
                    >
                      <option value="">{t("Choose section")} </option>
                      {sections.map((item) => (
                        <option value={item._id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className=" my-2">
                <label className="">
                  {t("Description")} <span className="required"></span>
                </label>
                <div
                  className={` border rounded-md mt-1 ${
                    errors.name && "border-red-600"
                  }`}
                >
                  <textarea
                    className="p-2 w-full outline-none bg-transparent"
                    {...register("description", {
                      required: t("description is required"),
                      minLength: {
                        value: 3,
                        message: t("description must be at least 3 characters"),
                      },
                    })}
                    type="text"
                    rows={10}
                    placeholder={t("Enter description")}
                  />
                </div>
                {errors.description && (
                  <p className="text-xs italic text-red-500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>
          </LayoutProductSection>

          <LayoutProductSection title={t("Stock & Pricing")}>
            <div className="my-2">
              <label className=" opacity-70">
                {t("Price")} <span className="required"></span>
              </label>
              {!hasVariation ? (
                <div className="mt-2 border rounded-md">
                  <input
                    className="p-2 w-full  bg-transparent"
                    type="text"
                    placeholder="0.00"
                    {...register("price", {
                      required: "Price is required",
                      valueAsNumber: true,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: "number",
                      },
                      min: {
                        value: 1,
                        message: "grether than 1",
                      },
                    })}
                    // value={product.price}
                    // onChange={(e) =>
                    //   setProduct({ ...product, price: e.target.value })
                    // }
                  />
                </div>
              ) : (
                t("Change price in variation ")
              )}
            </div>
            {errors.price && (
              <p className="text-xs italic text-red-500">
                {errors.price.message}
              </p>
            )}

            <div className=" my-2 ">
              <label className="opacity-70 ">
                {t("Quantity")} <span className="required "></span>
              </label>
              <div className="mt-2 border rounded-md">
                <input
                  type="text"
                  placeholder="0"
                  className="p-2 w-full outline-none bg-transparent"
                  {...register("countInStock", {
                    required: "Quantity is required ",
                    valueAsNumber: true,
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "number",
                    },
                    // min: {
                    //   value: 1,
                    //   message: "quant > than 1",
                    // },
                  })}

                  // value={product.countInStock}
                  // onChange={(e) =>
                  //   setProduct({ ...product, countInStock: e.target.value })
                  // }
                />
              </div>
              {errors.countInStock && (
                <p className="text-xs italic text-red-500">
                  {errors.countInStock.message}
                </p>
              )}
            </div>
          </LayoutProductSection>
          {/* 
          <LayoutProductSection title="Date">
            <p className="opacity-70 text-sm">
              Choose the date you want your product to be available.
            </p>
            <div className="py-2 flex items-center">
              Immediately ?
              <div class=" ">
                <label for="toggleImm" class="flex items-center cursor-pointer">
                  <div class="relative">
                    <input
                      type="checkbox"
                      id="toggleImm"
                      class="sr-only"
      
                      {...register("isImmediately")}
                    />
                    <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                    <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                  </div>
                </label>
              </div>
            </div>
            {isImmediately === false && (
              <div className="p-3 grid grid-cols-2 gap-2">
                <div className=" my-2 ">
                  <label className=" ">From Date</label>
                  <div className="border relative z-20 rounded-md">
                    <DatePicker
                      minDate={new Date()}
                      required
                      className="w-full border-none bg-transparent p-2 outline-none"
                      selected={fromDate}
                      onChange={(date) => setFromDate(date)}
                    />

                    <div className="absolute top-2 right-2 z-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className=" my-2 ">
                  <label className=" ">To Date</label>
                  <div className="border relative z-20 rounded-md">
                    <DatePicker
                      // minDate={new Date()}
                      required
                      disabled={hasNoEndDate}
                      className={`w-full disabled:bg-gray-50 border-none bg-transparent p-2 outline-none`}
                      selected={toDate}
                      onChange={(date) => setToDate(date)}
                    />
                    <div className="absolute top-2 right-2 z-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      {...register("hasNoEndDate")}
                      // checked={product.hasNoEndDate}
                    />
                    No End Date
                  </div>
                </div>
              </div>
            )}
          </LayoutProductSection> */}
          <LayoutProductSection title="Date">
            <p className="opacity-70 text-sm">
              {t("Choose the date you want your product to be available.")}
            </p>
            <div className="py-2 flex items-center">
              <input
                type="checkbox"
                checked={chooseDate}
                onChange={(v) => {
                  setChooseDate(!chooseDate);
                }}
                {...register("hasDate", {})}
              />
              <span
                onClick={() => setChooseDate(!chooseDate)}
                className=" cursor-pointer mx-2 "
              >
                Choose the date ?
              </span>
            </div>
            {chooseDate && (
              <div className="p-3 grid md:grid-cols-2 gap-2">
                <div className=" my-2 ">
                  <label className=" ">Choose Date</label>
                  <div className="border relative z-20 rounded-md">
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      // min={getTodayDate()}
                      className="w-full border-none bg-transparent p-2 outline-none"
                      value={eventDate}
                      onChange={(v) => setEventDate(v.target.value)}
                    />
                  </div>
                  {eventDateError ? (
                    <p class="text-xs italic text-red-500">{eventDateError}</p>
                  ) : null}
                </div>
              </div>
            )}
          </LayoutProductSection>

          <LayoutProductSection title={t("Variations")}>
            {!hasVariation && (
              <div className="flex">
                <div
                  onClick={() => setIsVariationOpen(true)}
                  className="border flex  p-2 cursor-pointer rounded-md"
                >
                  {t("Add variation")}
                </div>
              </div>
            )}

            <Variation
              product={product}
              setHasVariation={setHasVariation}
              setVariation={setVariation}
              variation={variation}
              setIsVariationOpen={setIsVariationOpen}
              isVariationOpen={isVariationOpen}
            />

            <div className="pt-3">
              <div className="flex items-center gap-3">
                <h1 className="italic">{t("Personnalisation")}</h1>
                <div class=" ">
                  <label
                    // onClick={handleDisableProduct(!product?.isActive)}
                    for="toggleB"
                    class="flex items-center cursor-pointer"
                  >
                    <div class="relative">
                      <input
                        type="checkbox"
                        id="toggleB"
                        class="sr-only"
                        // checked={isCustomized}
                        {...register("isCustomized")}
                      />
                      <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
                      <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                    </div>
                  </label>
                </div>
              </div>
              {/* custom */}
              {isCustomized && (
                <div className="pt-3 flex">
                  <div className="border w-full md:w-1/2 rounded-md">
                    <input
                      {...register("customizationTitle")}
                      placeholder="Ce que vous souhaitez demander à votre acheteur !"
                      className="p-2 w-full outline-none bg-transparent "
                    />
                  </div>
                </div>
              )}
            </div>
          </LayoutProductSection>

          <div className="flex justify-end gap-x-3 pt-4">
            <button className="border py-2 px-8 text-white rounded-md bg-primary">
              {t("Save")}
            </button>
            {/* <button
              onClick={submitHandler}
              className="rounded-md py-2 px-8 text-white  bg-primary"
            >
              Publier en ligne
            </button> */}
          </div>
        </form>
      </AdminLayout>
    </>
  );
};

export default ProductEditScreen2;
