import React from "react";
import AdminLayout from "../../layout/AdminLayout";
import axiosInstance from "../../axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paginate from "../../components/Paginate";
import { listTickets } from "../../actions/ticketActions";
import { useTranslation } from "react-i18next";

const CardItem = ({ title, number, icon }) => {
  return (
    <div className="border card-bg text-white rounded-xl px-4 py-3">
      <div className="flex items-center gap-3 text-xs">
        {icon} {title}
      </div>
      <div className="py-2 text-2xl font-extrabold">{number}</div>
    </div>
  );
};

function Tickets({ match }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const pageNumber = match.params.pageNumber || 1;

  const userLogin = useSelector((state) => state.userLogin);
  const { tickets, page, pages } = useSelector((state) => state.ticketList);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.access}`,
    },
  };

  useEffect(() => {
    axiosInstance
      .get("/tickets", config)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    dispatch(listTickets(pageNumber));
  }, [dispatch, pageNumber]);

  // useEffect(() => {
  //   axiosInstance
  //     .get(`/tickets/scanned?page=${pageNumber}`, config)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setTickets(res.data.tickets);
  //       setPage(res.data.page);
  //       setPages(res.data.pages);
  //     })
  //     .catch((error) => {
  //       setTickets([]);
  //     });
  // }, []);
  const { t } = useTranslation();
  return (
    <AdminLayout>
      <h1 className="text-xl pb-4">{t("Tickets")}</h1>
      <div className="grid md:grid-cols-2 gap-3">
        <CardItem number={data.tickets_scanned} title={t("Scanned tickets")} />
        <CardItem
          number={data.tickets_not_scanned}
          title={t("Unscanned tickets")}
        />
      </div>

      <div>
        <div className="py-4">
          <h1 className="">{t("History")} </h1>
        </div>
        <div class="flex flex-col bg-white rounded-md">
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-hidden">
                <table className="w-full">
                  <thead class="bg-white border-b">
                    <tr>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Ticket N°")}
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Order N°")}
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Client")}
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        {t("Scanned at")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.length > 0 &&
                      tickets.map((item) => (
                        <tr
                          key={item._id}
                          class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.ticketNo}
                          </td>
                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <a href={`/orderlist/${item.orderNo}`}>
                              {item.orderNo}
                            </a>
                          </td>
                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.firstName} {item.lastName}
                          </td>

                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item.scannedAt}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {tickets.length == 0 && (
                  <div class="text-sm col-span-3 text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    Les tickets scannés apparaîtront ici !
                  </div>
                )}
                <Paginate page={page} pages={pages} section={"tickets"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Tickets;
